<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form class="row" @submit.prevent="saveChallenge" @reset="onReset" >
              <div class="col-md-6">
                <h2 class="title-block">THÔNG TIN THỬ THÁCH</h2>

                <div class="row">
                  <b-form-group
                      id="input-cl-title"
                      label="Tên thử thách(*):"
                      label-for="input-cl-title"
                      class="col-md-12"

                  >
                    <b-form-input
                        id="input-cl-title"
                        v-model="form.title"
                        required
                        placeholder="Tên thử thách"
                        maxlength="50"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                      id="input-cl-code"
                      label="Mã CODE (unique) (*):"
                      label-for="input-cl-code"
                      class="col-md-5"
                      v-if="false"

                  >
                    <b-form-input
                        :disabled="inprogress"
                        id="input-cl-code"
                        v-model="form.code"
                        required
                        @keydown.space.prevent
                        placeholder="Mã CODE  thử thách"
                        maxlength="20"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                    id="input-group-2"
                    label="Mô tả Ngắn (*)"
                    label-for="input-sdesc"
                >
                  <b-form-textarea
                      id="input-sdesc"
                      v-model="form.short_desc"
                      required
                      placeholder="Nhập mô tả ngắn"
                      rows="3"
                      maxlength="90"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                    id="input-group-2"
                    label="Mô tả thử thách (*)"
                    label-for="input-2"
                >
                  <b-form-textarea
                      id="input-1"
                      v-model="form.desc"
                      required
                      placeholder="Nhập mô tả"
                      rows="5"
                      maxlength="1000"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                    id="input-type-2"
                    label="Chọn loại thử thách (*)"
                    label-for="ms-desc"
                >
                  <b-form-select
                      id="ms-type"
                      :disabled="inprogress"
                      v-model="form.type"
                      required
                      placeholder="Chọn loại thử thách"
                  >
                    <option :value="1">Đơn nhiệm</option>
                    <option :value="2">Đa nhiệm</option>
                  </b-form-select>
                </b-form-group>

                <div class="row">
                  <b-form-group label="icon (*):" class="col-md-6">
                    <div style="display: flex">
                      <div style="height: 30px; margin-right: 20px" v-if="form.icon.length >0">
                        <img
                            style="width: 38px; height: 38px"
                            :src="form.icon"
                            alt=""
                        />
                      </div>
                      <b-form-file
                          @change="onImageChange($event,'icon')"
                          placeholder="chọn icon"
                          :required="form.icon.length > 0?false:true"
                          accept="image/*"
                          type="file"
                      ></b-form-file>
                    </div>
                  </b-form-group>

                  <b-form-group label="Banner (*):" class="col-md-6">
                    <div style="display: flex">
                      <div style="height: 30px; margin-right: 20px" v-if="form.banner.length >0">
                        <img
                            style="width: 38px; height: 38px"
                            :src="form.banner"
                            :required="form.banner.length > 0?false:true"
                            alt=""
                        />
                      </div>
                      <b-form-file
                          @change="onImageChange($event,'banner')"
                          placeholder="chọn ảnh banner"
                          accept="image/*"
                          type="file"
                      ></b-form-file>
                    </div>
                  </b-form-group>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                        id="input-type-catid"
                        label="Danh mục(*):"
                        label-for="gift-catid"
                    >
                      <b-form-select
                          :disabled="inprogress"
                          id="gift-catid"
                          v-model="form.categories_id"
                          :options="categories"
                          lable=""
                          required
                          placeholder="Chọn loại quà tặng"
                      ></b-form-select>

                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                        id="lb-input-level"
                        label="Độ khó(*):"
                        label-for="input-level"
                    >
                      <b-form-select
                          :disabled="inprogress"
                          id="gift-type"
                          v-model="form.level"
                          lable=""
                          required
                          placeholder="Chọn độ khó"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </b-form-select>

                    </b-form-group>
                  </div>
                </div>

                <!-- Setup direction -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                        id="input-direction"
                        label="Chọn đích đến:"
                        label-for="direction"
                    >
                      <b-form-select
                          id="direction"
                          v-model="form.direction_data"
                          label="Chọn đích đến:"
                      >
                        <b-form-select-option v-for="(item, index) in list_direction" :value="item.id" :key="index">{{ item.name }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="col-md-6" >
                <h2 class="title-block">THỜI GIAN</h2>
                <div class="row">

                  <b-form-group
                      id="input-group-4"
                      label="Thời gian bắt đầu hiển thị (*):"
                      label-for="dp1"
                      class="col-md-6"
                  >
                    <date-picker
                        :disabled="inprogress"
                        id="dp1"
                        name="dp1"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                        v-model="form.publish_start_at"
                        type="datetime"
                        placeholder="Chọn thời gian"
                        :input-attr="{required: 'true',name:''}"
                        :disabled-date="disableDateStart"
                    ></date-picker>
                  </b-form-group>

                  <b-form-group
                      id="input-type-catid"
                      label="Screen hiển thị(*):"
                      label-for="gift-catid"
                      class="col-md-4"
                  >
                    <b-form-select
                        :disabled="inprogress"
                        id="gift-catid"
                        v-model="form.screen"
                        required
                    >
                      <b-form-select-option v-for="(item, index) in screens" :value="item.id" :key="index">{{ item.name }}</b-form-select-option>
                    </b-form-select>

                  </b-form-group>

                  <b-form-group
                      id="input-group-4"
                      label="Thời gian kết thúc hiển thị (*):"
                      label-for="dp2"
                      class="col-md-6"
                      v-if="false"
                  >
                    <date-picker
                        id="dp2"
                        name="dp2"
                        v-model="form.publish_end_at"
                        type="datetime"
                        placeholder="Chọn thời gian"
                        :input-attr="{required: 'true',name:''}"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                        :disabled-date="disabledBefor"
                    ></date-picker>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group
                      id="input-group-4"
                      label="Thời gian bắt đầu diễn ra thử thách (*):"
                      label-for="d3"
                      class="col-md-6"

                  >
                    <date-picker
                        :disabled="inprogress"
                        id="d3"
                        name="dp3"
                        v-model="form.date_start"
                        type="datetime"
                        placeholder="Chọn thời gian"
                        :input-attr="{required: 'true',name:''}"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                        :disabled-date="disabledJoinStart"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group
                      id="input-group-4"
                      label="Thời gian kết thúc thử thách (*):"
                      label-for="d4"
                      class="col-md-6"
                  >
                    <date-picker
                        id="d4"
                        name="dp4"
                        v-model="form.date_end"
                        type="datetime"
                        placeholder="Chọn thời gian"
                        :input-attr="{required: 'true',name:''}"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                        :disabled-date="disabledJoinEnd"
                    ></date-picker>

                  </b-form-group>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="form-inline col-md-12">
                        <input :disabled="inprogress" id="show-bn-cms" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.is_show_banner_coming_soon">
                        <label for="show-bn-cms"  style=" margin-left: 10px">Hiển thị banner Coming Soon</label>

                      </div>
                      <b-form-group label="Banner Coming Soon (*):" class="col-md-12" v-if="form.is_show_banner_coming_soon == '1'">
                        <div style="display: flex" >
                          <div style="height: 30px; margin-right: 20px" v-show="form.banner_coming_soon.length >0">
                            <img
                                style="width: 38px; height: 38px"
                                :src="form.banner_coming_soon"
                                alt=""
                            />
                          </div>
                          <b-form-file
                              v-if="!inprogress"
                              @change="onImageChange($event,'banner_coming_soon')"
                              :required="form.banner_coming_soon.length > 0?false:true"
                              placeholder="chọn ảnh banner coming soon"
                              accept="image/*"
                              type="file"
                          ></b-form-file>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">
                      <div class="form-inline col-md-12">
                        <input :disabled="inprogress"  id="kpi-time-ck" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.is_show_kpi_time">
                        <label for="kpi-time-ck" style=" margin-left: 10px">Có KPI thời gian hoàn thành thử thách?</label>
                      </div>
                      <b-form-group
                          id="input-cl-title"
                          label="KPI time:"
                          label-for="input-cl-kpi-time"
                          class="col-md-12"
                          v-if="form.is_show_kpi_time ===1 "
                      >
                        <b-form-input
                            id="input-cl-kpi-time"
                            :disabled="inprogress"
                            v-model="form.kpi_time"
                            required
                            type="number"
                            min="1"
                            placeholder="Thời gian bắt buộc phải hoàn thành nhiệm vụ"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="form-inline col-md-12">
                        <input id="is_display" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.is_display">
                        <label for="is_display"  style=" margin-left: 10px">Cho phép hiển thị</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="form-inline col-md-12">
                        <input id="is_auto_challenge" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.is_auto">
                        <label for="is_auto_challenge"  style=" margin-left: 10px">Tự động ghi nhận thử thách</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="form-inline col-md-12">
                        <input id="is_join_by_code" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.is_join_by_code">
                        <label for="is_join_by_code"  style=" margin-left: 10px">Cho phép join bằng mã code</label>
                      </div>
                      <div class="form-inline col-md-12" v-if="form.is_join_by_code">
                        <input id="custom_code" class="form-control" type="text" :true-value="1" :false-value="0" value="1" v-model="form.custom_code" placeholder="Nhập mã join thử thách">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="form-inline col-md-12">
                        <input id="is_hot" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.is_hot">
                        <label for="is_hot"  style=" margin-left: 10px">Thử thách đặc biệt</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <h2 class="title-block">ĐIỀU KIỆN HIỂN THỊ</h2>
                <div class="row">
                  <div class="form-inline col-md-12 ">
                    <input id="display_all_user"
                           :disabled="inprogress"
                           class="form-control"
                           type="checkbox"
                           :true-value="1"
                           :false-value="0"
                           value="1"
                           v-model="form.display_all_user"
                           @change="checkDisplaySegment"
                    >
                    <label for="display_all_user" style=" margin-left: 10px">Tất cả user</label>
                  </div>
                  <div class="col-md-6" v-if="form.display_all_user ===0">

                    <b-form-group
                        id="input-dk-2"
                        label="Tập user hiển thị (*) "
                        label-for="dk-2"

                    >
                      <multiselect
                          :disabled="inprogress"
                          v-model="display_segment.allow"
                          :options="list_segment"
                          :multiple="true"
                          track-by="name"
                          label="name"
                          id="display_box"
                          placeholder="Chọn tập user"
                      ></multiselect>
                    </b-form-group>
                  </div>
                  <div class="col-md-6" v-if="form.display_all_user ===0">
                    <b-form-group
                        id="input-dk-2"
                        label="Tập user không hiển thị "
                        label-for="dk-2"
                    >
                      <multiselect
                          :disabled="inprogress"
                          v-model="display_segment.block"
                          :options="list_segment"
                          :multiple="true"
                          track-by="name"
                          label="name"
                          placeholder="Chọn tập user"
                      ></multiselect>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h2 class="title-block">ĐIỀU KIỆN THỰC HIỆN THỬ THÁCH</h2>
                <div class="row">
                  <div class="form-inline col-md-12 ">
                    <input id="join_all_user"
                           :disabled="inprogress"
                           class="form-control"
                           type="checkbox"
                           :true-value="1"
                           :false-value="0"
                           value="1"
                           v-model="form.join_all_user"
                           @change="checkJoinSegment"
                    >
                    <label for="join_all_user" style=" margin-left: 10px">Tất cả user</label>
                  </div>
                  <div class="col-md-6" ref="join" v-if="form.join_all_user ===0">
                    <b-form-group
                        id="input-dk-2"
                        label="Tập user áp dụng (*)"
                        label-for="dk-2"

                    >
                      <multiselect
                          :disabled="inprogress"
                          v-model="join_segment.allow"
                          :options="list_segment"
                          :multiple="true"
                          track-by="name"
                          label="name"
                          id="join"
                          placeholder="Chọn tập user"
                      ></multiselect>
                    </b-form-group>
                  </div>
                  <div class="col-md-6" v-if="form.join_all_user ===0">
                    <b-form-group
                        id="input-dk-2"
                        label="Tập user không áp dụng"
                        label-for="dk-2"
                    >
                      <multiselect
                          :disabled="inprogress"
                          v-model="join_segment.block"
                          :options="list_segment"
                          track-by="name"
                          label="name"
                          :multiple="true"
                          placeholder="Chọn tập user"
                      ></multiselect>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h2 class="title-block">NHIỆM VỤ</h2>
                <template v-for="(item ,index) in missions"  >
                  <div :key="item.id+index">
                    <hr >
                    <div  class="row" >
                      <div class="col-md-6">
                        <h4 class="text-info">Nhiệm vụ #{{index+1}}</h4>

                      </div>
                      <div  class="col-md-6 text-right" >
                        <b-btn  v-if="index > 0" @click="deleteMission(index)" >Xóa</b-btn>
                      </div>

                      <div class="col-md-6">
                        <b-form-group
                            id="input-dk-1"
                            label="Chọn nhiệm vụ:"
                            label-for="dk-1"
                        >
                          <b-form-select
                              :disabled="inprogress"
                              v-model="missions[index].mission_selected"
                              :options="mission_option"
                              lable=""
                              @change="checkAdded(index)"
                              required
                              placeholder="Chọn nhiệm vụ"
                          ></b-form-select>

                        </b-form-group>
                        <b-form-group
                            id="input-dk-2"
                            label="Tiêu đề nhiệm vụ (*)"
                            label-for="ms-title"
                        >
                          <b-form-input
                              id="ms-title"
                              v-model="missions[index].title"
                              required
                              placeholder="Nhập tiêu đề nhiệm vụ"
                              type="text"
                              maxlength="50"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-dk-2"
                            label="Mô tả nhiệm vụ (*)"
                            label-for="ms-desc"
                        >
                          <b-form-textarea
                              id="ms-desc"
                              v-model="missions[index].desc"
                              required
                              placeholder="Nhập mô tả nhiệm vụ"
                              maxlength="200"
                              rows="4"
                          ></b-form-textarea>
                        </b-form-group>

                      </div>
                      <div class="col-md-6">
                        <div class="row" v-if="missions[index].mission_selected.code === 'PAYMENT' || missions[index].mission_selected.code === 'AUTO_PAYMENT'">

                          <div class="col-md-6">
                            <b-form-group
                                id="input-dk-1"
                                label="Kiểu thanh toán:"
                                label-for="dk-1"
                            >
                              <b-form-select
                                  :disabled="inprogress"
                                  v-model="payment_conditions[index].type"
                                  :options="payment_option_types"
                                  lable=""
                                  required
                                  placeholder="Chọn kiểu"
                              ></b-form-select>

                            </b-form-group>

                            <b-form-group
                                id="input-dk-3"
                                label="AUTO_PAYMENT"
                                label-for="dk-is_auto_payment"
                            >
                              <b-form-checkbox
                                  :disabled="inprogress"
                                  id="dk-is_auto_payment"
                                  v-model="payment_conditions[index].is_auto_payment"
                                  type="checkbox"
                                  :checked="1"
                                  :value="1"
                              ></b-form-checkbox>
                            </b-form-group>

                            <b-form-group
                                id="input-dk-1"
                                label="Dịch vụ (service type):"
                                label-for="dk-1"
                                description="Chú ý để trống để áp dụng cho tất cả dịch vụ"
                            >
                              <multiselect
                                  :disabled="inprogress"
                                  v-model="payment_conditions[index].list_service_code"
                                  :options="payment_option"
                                  @input="listServiceCodeChange(index)"
                                  :multiple="true"
                                  placeholder="Chọn dịch vụ"
                              ></multiselect>

                            </b-form-group>

                            <b-form-group
                                v-if="payment_conditions[index].list_service_code.includes('PAYGATE')"
                                id="input-dk-1"
                                label="Chọn merchant:"
                                label-for="dk-1"
                                description="Chú ý để trống để áp dụng cho tất cả merchants"
                            >
                              <multiselect
                                  :disabled="inprogress"
                                  v-model="payment_conditions[index].merchants"
                                  :options="list_merchants"
                                  :multiple="true"
                                  placeholder="Chọn merchants"
                              ></multiselect>

                            </b-form-group>

                            <b-form-group
                                v-if="payment_conditions[index].list_service_code.includes('PAYGATE')"
                                id="input-dk-1"
                                label="Nhập gameID:"
                                label-for="dk-1"
                                description="Chú ý để trống để áp dụng cho tất cả game thuộc merchants"
                            >
                              <input-tag v-model="payment_conditions[index].game_ids"></input-tag>
                            </b-form-group>

                            <b-form-group
                                label="Danh sách dịch vụ:(service code)"
                                label-for="dk-1"
                                description="Chú ý để trống để áp dụng cho tất cả dịch vụ "
                            >
                              <multiselect
                                  track-by="value"
                                  label="text"
                                  :disabled="inprogress"
                                  v-model="serviceCodeOptionSelected[index]"
                                  :options="getServiceCodeOption[index]"
                                  :multiple="true"
                                  placeholder="Chọn dịch vụ"
                              ></multiselect>

                            </b-form-group>
                            <b-form-group
                                id="input-dk-2"
                                label="Số tiền tối thiểu/1 giao dịch (*)"
                                label-for="dk-2"
                            >
                              <b-form-input
                                  id="dk-1"
                                  v-model="payment_conditions[index].min_per_trans"
                                  required
                                  type="number"
                                  placeholder="số tiền tối thiểu"
                                  :min="payment_conditions[index].current_min_per_trans"
                              ></b-form-input>
                            </b-form-group>
                          </div>

                          <div class="col-md-6">
                            <b-form-group
                                id="input-dk-3"
                                label="Tổng số tiền thanh toán (*)"
                                label-for="dk-3"
                                v-if="payment_conditions[index].type === 'DEFAULT'"
                            >
                              <b-form-input
                                  :disabled="inprogress"
                                  id="dk-3"
                                  v-model="payment_conditions[index].total_amount"
                                  required
                                  type="number"
                                  placeholder="Tổng số tiền thanh toán "
                                  min="1"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                v-else-if="payment_conditions[index].type === 'PAYMENT_LIMIT'"
                                id="input-dk-3"
                                label="Số lượt cần đạt (*)"
                                label-for="dk-3"
                            >
                              <b-form-input
                                  :disabled="inprogress"
                                  id="dk-3"
                                  v-model="payment_conditions[index].num_payment"
                                  required
                                  type="number"
                                  placeholder="Nhập số lần thanh toán "
                                  min="1"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="input-dk-3"
                                label="Cho phép hủy thử thách"
                                label-for="dk-allow_cancel"
                            >
                              <b-form-checkbox
                                  :disabled="inprogress"
                                  id="dk-allow_cancel"
                                  v-model="form.allow_cancel"
                                  type="checkbox"
                                  :checked="1"
                                  :value="1"
                                  @change="checkDisplaySegment"
                              ></b-form-checkbox>
                            </b-form-group>
                            <!--                        <div class="form-inline  ">
                                                      <input id="allow_cancel"
                                                             class="form-control"
                                                             type="checkbox"
                                                             :true-value="1"
                                                             :false-value="0"
                                                             value="1"
                                                             v-model="form.allow_cancel"
                                                             @change="checkDisplaySegment"
                                                      >
                                                      <label for="allow_cancel" style=" margin-left: 10px">Cho phép hủy thử thách</label>
                                                    </div>-->
                            <b-form-group
                                style="margin-top: 10px"
                                v-if="form.allow_cancel==1"
                                id="input-dk-3"
                                label="Số lần được từ bỏ (*)"
                                label-for="dk-3"
                                description="Để trống trường thông tin để không giới hạn số lần từ bỏ"
                            >
                              <b-form-input
                                  id="dk-3"
                                  v-model="form.num_cancel"
                                  type="number"
                                  placeholder="Nhập số lần được từ bỏ thử thách "
                                  :min="form.current_num_cancel"
                              ></b-form-input>
                            </b-form-group>

                          </div>
                        </div >
                        <!-- case map bank-->
                        <div class="row" v-if="missions[index].mission_selected.code === 'LINK_BANK'">
                          <div class="col-md-12">

                            <b-form-group
                                id="input-dk-222"
                                label="Loại liên kết (*)"
                                label-for="dk-222"
                            >
                              <b-form-select
                                  :disabled="inprogress"
                                  id="dk-222"
                                  v-model="bank_conditions.type_direct"
                                  :options="bank_condition_option"
                                  lable=""
                                  required
                                  placeholder="Chọn nhiệm vụ"
                              ></b-form-select>
                            </b-form-group>
                          </div>
                          <div class="col-md-12" v-if="bank_conditions.type_direct==1">
                            <b-form-group
                                id="input-dk-2"
                                label="Ngân hàng cụ thể:"
                                label-for="dk-2"
                                description="Bỏ trống để chọn tất cả"
                            >
                              <multiselect
                                  v-model="bank_conditions.list_bank_code"
                                  :options="bank_option"
                                  track-by="name"
                                  label="name"
                                  :multiple="true"
                                  placeholder="Chọn ngân hàng"
                              ></multiselect>
                            </b-form-group>
                          </div>
                        </div>
                        <!-- case nap tien-->
                        <div class="row" v-if="missions[index].mission_selected.code === 'DEPOSIT'">
                          <div class="col-md-12">

                            <b-form-group
                                id="input-dk-222"
                                label="Loại Ngân hàng (*)"
                                label-for="bank-type"
                            >
                              <b-form-select
                                  :disabled="inprogress"
                                  id="bank-type"
                                  v-model="deposit_conditions.type"
                                  lable=""
                                  required
                                  placeholder="Chọn nhiệm vụ"
                              >
                                <option value="DIRECT_LINK">Liên kết trực tiếp</option>
                                <option value="NAPAS">Napas</option>
                                <option value="NAPAS_PAYGATE">Napas Cổng</option>
                              </b-form-select>
                            </b-form-group>
                          </div>
                          <div class="col-md-12" >
                            <b-form-group
                                id="input-dk-23"
                                label="Số tiền tối thiểu/1 giao dịch (*)"
                                label-for="dk-31"
                            >
                              <b-form-input
                                  id="dk-31"
                                  v-model="deposit_conditions.min_amount"
                                  required
                                  type="number"
                                  placeholder="số tiền tối thiểu"
                                  :min="0"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-12" >
                            <b-form-group
                                id="input-dk-23"
                                label="Tổng số tiền:"
                                label-for="dk-31"
                                description="Có thể để trống"
                            >
                              <b-form-input
                                  id="dk-31"
                                  v-model="deposit_conditions.total_amount"
                                  type="number"
                                  placeholder="Tổng số tiền tiền cần nạp"
                                  :min="0"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <!-- case điểm danh-->
                        <div class="row" v-if="missions[index].mission_selected.code === 'ATTENDANCE' || missions[index].mission_selected.code === 'REFER'">

                          <div class="col-md-12">
                            <b-form-group
                                id="input-dk-2"
                                :label="missions[index].mission_selected.code === 'ATTENDANCE' ?'Nhập số ngày cần điểm danh(*)':'Nhập số bạn bè cần mời'"
                                label-for="dk-2"
                            >
                              <b-form-input
                                  :disabled="inprogress"
                                  id="dk-2"
                                  v-model="attendance_conditions.num"
                                  required
                                  type="number"
                                  min="1"
                                  :placeholder="missions[index].mission_selected.code === 'ATTENDANCE' ?'Nhập số ngày':'Nhập số bạn bè cần mời'  "
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <!-- case đăng ký-->
                        <div class="row" v-if="missions[index].mission_selected.code === 'AUTO_REGISTER'">
                          <div class="col-md-6">
                            <b-form-group
                                :label="`Channel`"
                                label-for="dk-2"
                            >
                              <multiselect
                                  :disabled="inprogress"
                                  v-model="register_conditions.channels"
                                  :options="list_channels"
                                  track-by="name"
                                  label="name"
                                  :multiple="true"
                                  placeholder="Chọn channel"
                              ></multiselect>

                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                :label="`Agency`"
                                label-for="dk-2"
                            >
                              <multiselect
                                  :disabled="inprogress"
                                  v-model="register_conditions.agencies"
                                  :options="list_agencies"
                                  track-by="name"
                                  label="name"
                                  :multiple="true"
                                  placeholder="Chọn agency"
                              ></multiselect>
                            </b-form-group>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </template>
                <div class="mb-4"  v-if="missions.length < mission_option.length">
                  <a href="javascript:;" @click="addMission()" v-if="form.type==2" > Thêm nhiệm vụ </a>
                </div>

              </div>

              <div class="col-md-12" >
                <h2 class="title-block">QUÀ TẶNG</h2>
                <div v-if="form.type ==1" class="row">
                  <div class="col-md-3">
                    <b-form-group
                        id="input-type-gift"
                        label="Kiểu quà tặng:"
                        label-for="gift-type"
                    >
                      <b-form-select
                          :disabled="inprogress"
                          id="gift-type"
                          v-model="mission_gifts.type"
                          :options="type_gift"
                          lable=""
                          required
                          placeholder="Chọn loại quà tặng"
                      ></b-form-select>

                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <div class="row" v-if="mission_gifts.type === 1">
                      <b-form-group
                          id="input-dk-2"
                          label="Voucher Code (*)"
                          label-for="dk-2"
                          class="col-md-6"
                      >
                        <b-form-input
                            :disabled="inprogress"
                            id="dk-2"
                            @keydown.space.prevent
                            v-model="mission_gifts.campaign_code"
                            @change="removeSpace"
                            required
                            placeholder="Voucher Code"
                        ></b-form-input>
                        <b-btn   class="btn btn-sm btn-info" @click="checkInfoCoupon(mission_gifts.campaign_code)">
                          Check
                        </b-btn>
                      </b-form-group>
                      <b-form-group
                          id="input-dk-2"
                          label="Tổng số lượng quà phát ra (*)"
                          label-for="dk-2"
                          class="col-md-6"
                      >
                        <b-form-input
                            id="dk-2"
                            v-model="mission_gifts.quantity"
                            required
                            type="number"
                            min="1"
                            placeholder="Tổng số lượng quà phát ra"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="row" v-if="mission_gifts.type === 3">
                      <b-form-group
                          id="input-dk-2"
                          label="Số điểm cộng (*)"
                          label-for="dk-2"
                          class="col-md-6"
                      >
                        <b-form-input
                            :disabled="inprogress"
                            id="dk-2"
                            v-model="mission_gifts.point"
                            required
                            type="number"
                            min="1"
                            placeholder="Số điểm cộng"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                          id="input-dk-2"
                          label="Tổng số lượng quà phát ra (*)"
                          label-for="dk-2"
                          class="col-md-6"
                      >
                        <b-form-input
                            id="dk-2"
                            v-model="mission_gifts.quantity"
                            required
                            type="number"
                            min="1"
                            placeholder="Tổng số lượng quà phát ra"
                        ></b-form-input>
                      </b-form-group>

                    </div>

                    <div class="row" v-if="mission_gifts.type === 2">
                      <b-form-group
                          id="input-dk-2"
                          label="Mệnh giá tiền thưởng (*)"
                          label-for="dk-2"
                          class="col-md-6"
                          description="Mệnh giá tối thiểu 100đ"
                      >
                        <b-form-input
                            :disabled="inprogress"
                            id="dk-2"
                            v-model="mission_gifts.amount"
                            required
                            type="number"
                            min="100"
                            placeholder="Mệnh giá tiền thưởng"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                          id="input-dk-2"
                          label="Tổng số lượng quà phát ra (*)"
                          label-for="dk-2"
                          class="col-md-6"
                      >
                        <b-form-input
                            id="dk-2"
                            v-model="mission_gifts.quantity"
                            required
                            type="number"
                            min="1"
                            placeholder="Tổng số lượng quà phát ra"
                        ></b-form-input>
                      </b-form-group>

                    </div>
                    <div class="row" v-if="mission_gifts.type === 4">
                      <b-form-group
                          id="input-dk-2"
                          label="Combo quà tặng (*)"
                          label-for="dk-2"
                          class="col-md-6"
                      >
                        <b-form-select
                            :disabled="inprogress"
                            v-model="mission_gifts.combo_id"
                            :options="combo_option"
                            lable=""
                            required
                            placeholder="Chọn combo"
                        ></b-form-select>

                      </b-form-group>
                      <b-form-group
                          id="input-dk-2"
                          label="Tổng số lượng quà phát ra (*)"
                          label-for="dk-2"
                          class="col-md-6"
                      >
                        <b-form-input
                            id="dk-2"
                            v-model="mission_gifts.quantity"
                            required
                            type="number"
                            min="1"
                            placeholder="Tổng số lượng quà phát ra"
                        ></b-form-input>
                      </b-form-group>

                    </div>

                  </div>
                  <div class="col-md-3" v-if="mission_gifts.type !=99">
                    <b-form-group
                        id="input-type-gift"
                        label="Router (*):"
                        label-for="gift-type"
                    >
                      <b-form-select
                          :disabled="inprogress"
                          id="gift-type"
                          v-model="form.router_complete"
                          lable=""
                          required
                          placeholder="Chọn router"
                      >
                        <option value="user_bag">Túi đồ</option>
                        <option value="historyPromotionWallet">Lịch sử ví thưởng</option>
                        <option value="presentationLoyalty">Điểm 9Point</option>
                      </b-form-select>

                    </b-form-group>
                  </div>
                </div>
                <div v-if="form.type ==1 && mission_gifts.type !=99" class="row" >
                  <div class="col-md-3">
                    <b-form-group
                        id=""
                        label="SL phát tối đa trong ngày/user"
                        label-for="input-max-in-day"
                    >
                      <b-form-input
                          id="input-max-in-day"
                          v-model="mission_gifts.max_in_day"
                          type="number"
                          min="1"
                          placeholder="SL phát tối đa trong ngày/user"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <b-form-group
                        id=""
                        label="SL phát tối đa trong campaign/user"
                        label-for="input-max-in-camp"
                    >
                      <b-form-input
                          id="input-max-in-camp"
                          v-model="mission_gifts.max_in_camp"
                          type="number"
                          min="1"
                          placeholder="SL phát tối đa trong campaign/user"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                </div>

                <div v-if="form.type ==2" class="row">
                  <div class="col-md-6">
                    <b-form-group
                        id="input-type-gift"
                        label="Kịch bản phát quà:"
                        label-for="script-gift-type"
                    >
                      <b-form-select
                          id="script-gift-type"
                          v-model="form.script_id"
                          :options="scripts"
                          lable=""
                          required
                          placeholder="Chọn kịch bản phát quà"
                      ></b-form-select>

                    </b-form-group>
                  </div>
                </div>

                <h5 class="" v-if="mission_gifts.type !=99"> Thông tin quà tặng </h5>
                <div v-if="mission_gifts.type !=99">
                  <template   v-for="( item ,index ) in info_gifts " >
                    <div class="row"  v-bind:key="index" >
                      <div class="col-md-3">
                        <b-form-group
                            :id="`input-type-gift ${index + 1}`"
                            label="Chọn icon: (*)"
                            :label-for="`gift-type${index + 1}`"
                        >
                          <div style="display: flex">
                            <div style="height: 30px; margin-right: 20px" v-if="info_gifts[index].icon.length >0">
                              <img
                                  style="width: 38px; height: 38px"
                                  :src="info_gifts[index].icon"
                                  alt=""
                              />
                            </div>
                            <b-form-file
                                @change="onImageChange($event,'info_gifts',index)"
                                placeholder="chọn icon"
                                :required="info_gifts[index].icon.length > 0?false:true"
                                accept="image/*"
                                type="file"
                            ></b-form-file>
                          </div>

                        </b-form-group>
                      </div>
                      <div class="col-md-4" >
                        <b-form-group
                            id="input-dk-2"
                            label="Tiêu đề (*)"
                            :label-for="`dk-2-${index + 1}`"
                        >
                          <b-form-input
                              :id="`dk-2-${index + 1}`"
                              v-model="info_gifts[index].title"
                              required
                              maxlength="50"
                              placeholder="Nhập tiêu đề"
                          ></b-form-input>
                        </b-form-group>

                      </div>
                      <div class="col-md-4" >
                        <b-form-group
                            id="input-dk-2"
                            label="Mô tả(*)"
                            :label-for="`dk-2-${index + 1}`"
                        >
                          <b-form-input
                              :id="`dk-2-${index + 1}`"
                              v-model="info_gifts[index].desc"
                              required
                              placeholder="Nhập mô tả"
                              maxlength="100"
                          ></b-form-input>
                        </b-form-group>

                      </div>
                      <div class="col-md-1">
                        <b-button
                            v-if=" (info_gifts.length > 1 && index != 0 ) "
                            @click="deleteItem(index)"
                            pill
                            variant="danger"
                            style="margin-top: 29px"
                            size="sm"
                        >
                          <i class="icon flaticon2-trash"></i>
                        </b-button>
                      </div>

                    </div>

                  </template>
                </div>

                <div class="row" v-if="mission_gifts.type===4 && info_gifts.length <10  && !inprogress"   >
                  <div class="col-md-12">
                    <div class="" style="margin-bottom: 20px" >
                      <a href="javascript:;" v-if="info_gifts.length <10 && !inprogress" @click="addGiftInfo()">Thêm thông tin quà tặng</a>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-12">
                <h2 class="title-block">CÀI ĐẶT THÔNG BÁO</h2>
                <div class="row">
                  <div class="col-md-8">

                    <b-form-group
                        id="noti-title"
                        label="Tiêu đề thông báo (*):"
                        label-for="input-4"
                    >
                      <b-form-input
                          id="input-noti-title"
                          v-model="form.noti_title"
                          required
                          type="text"
                          placeholder="Tiêu đề thông báo"
                          maxlength="50"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-5"
                        label="Nội dung thông báo (*)"
                        label-for="input-5"
                    >
                      <b-form-textarea
                          id="input-5"
                          v-model="form.noti_desc"
                          required
                          placeholder="Mô tả thông báo"
                          type="text"
                          maxlength="300"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-form-group label="Chọn hình ảnh thông báo (optional):">
                      <div style="display: flex">
                        <div style="height: 30px; margin-right: 20px" v-if="form.noti_thumb.length >0">
                          <img
                              style="width: 38px; height: 38px"
                              :src="form.noti_thumb"
                              alt=""
                          />
                        </div>
                        <b-form-file
                            @change="onImageChange($event,'noti_thumb')"
                            placeholder="chọn ảnh thumb"
                            accept="image/*"
                            type="file"
                        ></b-form-file>
                      </div>
                    </b-form-group>
                  </div>

                </div>
              </div>

              <div class="col-md-12">
                <h2 class="title-block">CÀI ĐẶT WHITELIST</h2>
                <div class="row">
                  <div class="col-md-8">

                    <b-form-group
                        id="input-group-5"
                        label="Nhập những id user ( Bỏ trống để áp dụng cho tất cả )"
                        description="userId nhập cách nhau bởi dấu phẩy VD: 123321,456444,123123"
                        label-for="input-5"
                    >
                      <b-form-textarea
                          id="input-5"
                          v-model="form.users_white_list"
                          placeholder="Mô tả thông báo"
                          type="text"
                          maxlength="300"
                      ></b-form-textarea>
                    </b-form-group>

                  </div>

                </div>
              </div>


              <div class="col-md-12">
                <b-button type="submit" v-if="!complete" variant="primary">{{
                    is_edit ? "Cập nhập" : "Xác nhận"
                  }}
                </b-button>
                <router-link v-if="is_edit" :to="'/campaign/challenge'">
                  <b-button variant="danger">Hủy bỏ</b-button>
                </router-link>
                <b-button v-else type="reset" variant="danger">Làm mới</b-button>
              </div>
            </b-form>
            <alert-dialogue ref="confirmDialogue"></alert-dialogue>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component("multiselect", Multiselect);
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletRunRepository = RepositoryFactory.get("WalletRun");
const CmsRepository = RepositoryFactory.get("cms");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
export default {
  name: "FormMission",
  mixins: [Common],
  components: {
    Multiselect,
    DatePicker  ,
    AlertDialogue
  },
  data() {
    return {
      serviceCodeOption:[[]],
      serviceCodeOptionSelected:[[]],
      form: this.formInit(),
      current_quantity_gift:0,
      inprogress:false,
      complete:false,
      list_channels: [
        {
          name: 'APP',
          value: 'APP'
        },
        {
          name: 'SDK',
          value: 'SDK'
        }
      ],
      list_agencies: [
        {
          name: 'DEFAULT',
          value: 'DEFAULT'
        },
        {
          name: 'LIVWELL',
          value: 'LIVWELL'
        },
        {
          name: 'SDK_TEST',
          value: 'SDK_TEST'
        }
      ],
      register_conditions: {
        agencies: [],
        channels: []
      },
      bank_conditions: {
        type_direct:0,
        list_bank_code:[]
      },
      payment_conditions: [
        this.payment_condition_define()
      ],
      deposit_conditions: {
        type: '',
        total_amount:'',
        min_amount:'',
      },
      attendance_conditions: {
        num:1
      },
      missions: [
        this.ms_define()
      ],
      mission_selected:{},
      mission_noti: {
        noti_title:'',
        noti_desc:'',
        noti_thumb:'',
      },

      display_segment: {
        allow:[],
        block:[],
      },
      join_segment: {
        allow:[],
        block:[],
      },
      mission_type_option: [{ text: "Chọn loại nhiệm vụ", value: null }],
      bank_option: [],
      payment_option: [],
      payment_option_types: [
        {
          text: "Mặc định",
          value: "DEFAULT"
        },
        {
          text: "Số lần thanh toán",
          value: "PAYMENT_LIMIT"
        },
        {
          text: "Lần đầu thanh toán",
          value: "PAYMENT_FIRST"
        }
      ],
      mission_option: [],
      bank_condition_option: [
        {
          text:'Tất cả ngân hàng',
          value:0,
        },
        {
          text:'Chỉ tính liên kết trực tiếp',
          value:1,
        },
      ],
      combo_option: [],
      type_gift: [],
      scripts: [],
      categories: [],
      list_segment: [],
      list_segment_save: [],
      show: true,
      is_edit: false,
      old:{
        date_end:'',
        publish_end_at:'',
      },
      info_gifts: [
        {
          icon:'',
          title:'',
          desc:'',
        }
      ],
      mission_gifts: {
        type:'',
        campaign_code:'',
        quantity:'',
        point:'',
        amount:'',
        max_in_day:'',
        max_in_camp:'',
        combo_id:'',
      },
      screens: []
    };

  },
  computed:{
    getServiceCodeOption(){
      return this.serviceCodeOption
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí thử thách ", route: "/campaign/challenge" },
      { title: "Thêm thử thách" },
    ]);
    this.$nextTick(() => {
      document.getElementById("join").setAttribute("required", "") ;
      document.getElementById("display_box").setAttribute("required", "") ;
    });



  },
  watch:{
    'display_segment.allow':function () {
      this.checkDisplaySegment()
    },
    'join_segment.allow':function () {
      this.checkJoinSegment();
    }
  },
  methods: {
    async checkInfoCoupon (code) {
      let self = this;
      let response = await CampaignRepository.getInfoCoupon(code);
      if (response.status !== 200 || response.data.error_code === 1) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }

      self.$bus.$emit("show-loading", false);
      const cfm = await this.$refs.confirmDialogue.show({
        title: "Thông tin Voucher",
        message:
            "<ul style='text-align: left'><li>"+
            "ID:  " +response.data.data.id+
            "</li><li>" +
            "Code: " +response.data.data.code+
            "</li><li>" +
            "Tên: " +response.data.data.name+
            "</li><li>" +
            "Số lượng: "+this.numberWithCommas(response.data.data.max_gift)+"</li></ul>",
        okButton: "Ok",
      });

      if (!cfm) {
        this.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }

      this.$bus.$emit("show-loading", false);

    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    checkAdded(msIndex){
      return msIndex;
      //let codes = this.missions.filter(item => item.mission_selected.code).map(item => item.mission_selected.code);

      /*if (this.getOptionsAdded.includes(this.missions[msIndex].mission_selected.code)){
        this.missions[msIndex].mission_selected={}
        this.$notify({
          group: "foo",
          type: "error",
          title:"Nhiệm vụ đã được thêm vui lòng chọn nhiệm vụ khác",
        });
        return false;
      }*/
    },
    deleteMission(index){
      this.missions.splice(index,1)
    },
    ms_define(){
      return {
        id:'',
        mission_id:'',
        code:'',
        title:'',
        desc:'',
        type:'',
        campaign_code:'',
        quantity:'',
        point:'',
        amount:'',
        max_in_day:'',
        max_in_camp:'',
        combo_id:'',
        mission_selected:{},
      }
    },
    payment_condition_define(){
      return {
        type: 'DEFAULT',
        total_amount:'',
        min_per_trans:'',
        current_min_per_trans:0,
        list_service_code:[],
        list_service_code_child:[],
        num_payment: '',
        merchants: [],
        game_ids: [],
        is_auto_payment: 0
      }
    },
    addMission(){
      this.missions.push(this.ms_define())
      this.payment_conditions.push(this.payment_condition_define())
      this.serviceCodeOption.push([]);
    },
    addGiftInfo(){
      if (this.info_gifts.length >=10){
        return false
      }
      this.info_gifts.push({
        icon:'',
        title:'',
        desc:'',
      });
    },
    removeSpace(){
      this.mission_gifts.campaign_code = this.mission_gifts.campaign_code.split(/\s+/).join('')
    },
    checkJoinSegment(){
      if (this.form.join_all_user ===1){
        return false
      }
      this.$nextTick(() => {
        if (this.join_segment.allow.length > 0){
          document.getElementById("join").removeAttribute("required") ;
        }else {
          document.getElementById("join").setAttribute("required", "") ;
        }
      })
    },
    checkDisplaySegment(){
      if (this.form.display_all_user ===1){
        return false
      }
      this.$nextTick(() => {
        if (this.display_segment.allow.length > 0){
          document.getElementById("display_box").removeAttribute("required") ;
        }else {
          document.getElementById("display_box").setAttribute("required", "") ;
        }
      })

    },
    deleteItem(index){
      if (confirm("Bạn có chắc muốn xóa thông tin này ?")) {
        this.info_gifts.splice(index, 1);
      }
    },
    disabledBefor(date){
      return date  <  Date.parse(this.form.publish_start_at);
    },
    disableDateStart(date){
      let current = new Date();
      return date  < new Date().setDate(current.getDate() - 1);
    },
    disabledJoinEnd(date){
      return date  <  Date.parse(this.form.date_start) || date  >  Date.parse(this.form.publish_end_at);
    },
    disabledJoinStart(date){
      return date <  Date.parse(this.form.publish_start_at) || date >  Date.parse(this.form.publish_end_at);
    },
    formInit(){
      return {
        title: "",
        code: "",
        desc: "",
        short_desc: "",
        allow_cancel: 0,
        num_cancel: '',
        current_num_cancel: '',
        publish_start_at: "",
        publish_end_at: "",
        date_start: "",
        date_end: "",
        num: "",
        is_multiple: "",
        conditions: "",
        status: 1,
        icon: '',
        banner: '',
        kpi_time: '',
        is_show_banner_coming_soon:0,
        banner_coming_soon:'',
        is_show_kpi_time:0,
        data_mission:'',
        display_segment_allow_user: [],
        display_segment_allow_block: [],
        join_segment_allow_user: [],
        join_segment_allow_block: [],
        noti_title:'',
        noti_desc:'',
        noti_thumb:'',
        display_all_user:0,
        join_all_user:0,
        info_gifts:[],
        categories_id:'',
        level:'',
        router_complete:'',
        screen: '',
        is_auto: 0,
        type: 1,
        script_id: '',
        is_join_by_code: 0,
        custom_code: '',
        is_display: 1,
        users_white_list: '',
        direction_data:'',
        is_hot: 0
      }
    },
    onImageChange(e,type,position) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia(type,position);
      //this.createImage(files[0]);
    },
    uploadMedia: async function (type,position) {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              if (type =='noti_thumb'){
                this.form.noti_thumb = response.data.data.link;
              }else if (type =='banner'){
                this.form.banner = response.data.data.link;
              }else if (type =='banner_coming_soon'){
                this.form.banner_coming_soon = response.data.data.link;
              }else if (type =='info_gifts'){
                this.info_gifts[position].icon = response.data.data.link;
              }else {
                this.form.icon = response.data.data.link;
              }
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadChallenge(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getChallenge(id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.list_segment_save = body.data.conditions
          this.initSegment()

          this.form.title = body.data.title+"_copy";
          this.form.desc = body.data.desc;
          this.form.short_desc = body.data.short_desc;
          this.form.allow_cancel = body.data.allow_cancel;
          this.form.num_cancel = body.data.num_cancel;
          this.form.code = body.data.code;
          this.form.noti_title = body.data.noti_title;
          this.form.noti_desc = body.data.noti_desc;
          this.form.num = body.data.num;
          this.form.is_show_banner_coming_soon = body.data.is_show_banner_coming_soon;
          this.form.is_show_kpi_time = body.data.is_show_kpi_time;
          this.form.kpi_time = body.data.kpi_time;
          this.current_kpi_time = body.data.kpi_time;
          this.form.display_all_user = body.data.display_all_user;
          this.form.join_all_user = body.data.join_all_user;
          this.form.level = body.data.level;
          this.form.categories_id = body.data.categories_id;
          this.form.router_complete = body.data.router_complete;
          this.form.screen = body.data.screen;
          this.form.is_auto = body.data.is_auto;
          this.form.script_id = body.data.script_id;
          this.form.type = body.data.type;
          this.form.custom_code = body.data.custom_code;
          this.form.is_join_by_code = body.data.is_join_by_code;
          this.form.is_display = body.data.is_display;
          this.form.users_white_list = body.data.users_white_list;
          this.form.is_hot =  body.data.is_hot;

          if (body.data.icon!= null){
            this.form.icon = body.data.icon;
          }
          if (body.data.banner!= null){
            this.form.banner = body.data.banner;
          }
          if (body.data.banner_coming_soon!= null){
            this.form.banner_coming_soon = body.data.banner_coming_soon;
          }
          let  data_noti =JSON.parse( body.data.notifications);

          this.form.noti_title = data_noti.title
          this.form.noti_desc = data_noti.desc
          if (data_noti.thumb !=null){
            this.form.noti_thumb = data_noti.thumb
          }
          //let  challenge_mission = body.data.challenge_missions;
          let  challenge_mission = body.data.challenge_missions;
          this.missions = [];
          this.serviceCodeOption = [];
          challenge_mission.forEach((item,index)=>{
            this.payment_conditions[index] = this.payment_condition_define();
            this.serviceCodeOption[index] = [];
            let selectedMission = body.data.missions.find(ms => ms.id === item.mission_id);
            if (selectedMission) {
              item.mission_selected = {
                code: selectedMission.code,
                id: selectedMission.id
              };
            }
            if (item.conditions !=null){
              if(item.mission_selected.code ==="LINK_BANK"){

                let conditions = JSON.parse(item.conditions);
                this.bank_conditions.type_direct = conditions.type_direct;
                const filteredOptions = this.bank_option.filter(option => conditions.list_bank_code.includes(option.code));
                this.bank_conditions.list_bank_code.push(...filteredOptions);
              }
              if(item.mission_selected.code ==="DEPOSIT"){
                let conditions = JSON.parse(item.conditions);
                this.deposit_conditions.type = conditions.type;
                this.deposit_conditions.min_amount = conditions.min_amount;
                this.deposit_conditions.total_amount = conditions.total_amount;
              }
              if(item.mission_selected.code ==="PAYMENT" || item.mission_selected.code ==="AUTO_PAYMENT"){
                let conditions = JSON.parse(item.conditions);
                this.payment_conditions[index].is_auto_payment = conditions.is_auto_payment??0;
                this.payment_conditions[index].min_per_trans = conditions.min_per_trans;
                this.payment_conditions[index].total_amount = conditions.total_amount;
                this.payment_conditions[index].num_payment = conditions.num_payment;
                this.payment_conditions[index].type = conditions.type;
                this.payment_conditions[index].list_service_code = conditions.list_service_code;
                this.listServiceCodeChange(index,true);
                this.serviceCodeOptionSelected[index] = [];
                this.payment_conditions[index].list_service_code_child = conditions.list_service_code_child;
                this.payment_conditions[index].merchants = conditions.merchants;
                this.payment_conditions[index].game_ids = conditions.game_ids;

              }
              if(item.mission_selected.code ==="ATTENDANCE" || item.mission_selected.code ==="REFER" ){
                let conditions = JSON.parse(item.conditions);
                this.attendance_conditions.num = conditions.num;
              }

              if(item.mission_selected.code === "AUTO_REGISTER"){
                let conditions = JSON.parse(item.conditions);

                conditions.channels.forEach((item) => {

                  this.register_conditions.channels.push({
                    name: item,
                    value: item,
                  })
                });
                conditions.agencies.forEach((item) => {
                  this.register_conditions.agencies.push({
                    name: item,
                    value: item,
                  })
                });
              }
            }
            this.missions.push(item)
          })


          //let  challenge_mission = body.data.challenge_missions;

          if (this.form.type ==1){
            let cl_mission = challenge_mission[0];
            this.mission_gifts.type = cl_mission.type
            this.mission_gifts.campaign_code = cl_mission.campaign_code
            this.mission_gifts.quantity = cl_mission.quantity
            this.current_quantity_gift = cl_mission.quantity
            this.mission_gifts.amount = cl_mission.amount
            this.mission_gifts.point = cl_mission.point
            this.mission_gifts.max_in_day = cl_mission.max_in_day
            this.mission_gifts.max_in_camp = cl_mission.max_in_camp
            this.mission_gifts.combo_id = cl_mission.combo_id
          }
          if (body.data.info_gifts!==null && body.data.info_gifts){
            this.info_gifts = JSON.parse(body.data.info_gifts)
          }
          if (body.data.extra_data!==null){
            let data_direc = JSON.parse(body.data.extra_data);
            this.form.direction_data= data_direc[0].id;
          }

        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getCodes() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getOptions();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.options = body.data.mission_code

          let that = this;
          this.options.forEach((item) => {
            that.mission_type_option.push(item);
          });
          body.data.bank_direct_link.forEach((item) => {
            that.bank_option.push(item);
          });
          body.data.payment_service.forEach((item) => {
            that.payment_option.push(item);
          });

          this.screens = body.data.challenge_screen

          //body.data.payment_types.forEach((item) => {
          //that.payment_option_types.push(item);
          //});
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getMission() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getList();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let that = this;
          body.data.data.forEach((item) => {
            let newObj = {
              text:item.title,
              value:{
                code:item.code,
                id:item.id
              },
            }
            that.mission_option.push(newObj);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getCombos() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getListCombo({all:true});
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let that = this;
          body.data.list.data.forEach((item) => {
            let newObj = {
              text:item.title,
              value:item.id
            }
            that.combo_option.push(newObj);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getTypeGift() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getOptionsVoucher();
        this.$bus.$emit("show-loading", false);
        this.type_gift = response.data.data;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getScripts() {
      let response = await WalletCampaignRepository.getListAllScripts();
      if (response.data.data){
        response.data.data.forEach(item=>{
          this.scripts.push(
              {
                value:item.id,
                text:item.event.name,
              }
          )
        })
      }
    },
    async getCategoryList() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getCategoryList();
        this.$bus.$emit("show-loading", false);
        let that = this;
        response.data.data.forEach((item) => {
          let newObj = {
            text:item.name,
            value:item.id
          }
          that.categories.push(newObj);
        });
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getSegment() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.listAllSegment({});
        this.$bus.$emit("show-loading", false);
        //this.list_segment = response.data.data;
        let that = this;
        response.data.data.data.forEach((item) => {
          if (item.status === 2){
            let newObj = {
              name:item.title,
              value:item.id
            }
            that.list_segment.push(newObj);
          }

        });
        this.initSegment();
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    rebuildParamsMission(missionItem,index){
      if (missionItem.mission_selected.code === "LINK_BANK"){
        let conditions = {
          type_direct:this.bank_conditions.type_direct,
          list_bank_code:[]
        }
        this.bank_conditions.list_bank_code.forEach((item)=>{
          conditions.list_bank_code.push(item.code)
        })
        missionItem.conditions = JSON.stringify(conditions)
        this.form.num =1;
        this.form.allow_cancel =0;
        this.form.num_cancel =0;
      }
      if (missionItem.mission_selected.code === "DEPOSIT"){
        missionItem.conditions = JSON.stringify(this.deposit_conditions)
        this.form.num =1;
        this.form.allow_cancel =0;
        this.form.num_cancel =0;
      }
      if (missionItem.mission_selected.code === "PAYMENT" || missionItem.mission_selected.code === "AUTO_PAYMENT" ){
        this.payment_conditions[index].list_service_code_child =[]
        if(typeof  this.serviceCodeOptionSelected[index] !=="undefined" && this.serviceCodeOptionSelected[index].length >0 ){
          this.serviceCodeOptionSelected[index].forEach((item)=>{
            this.payment_conditions[index].list_service_code_child.push(item.value)
          })
        }
        missionItem.conditions = JSON.stringify(this.payment_conditions[index])
        this.form.is_multiple=1;
      }
      if (missionItem.mission_selected.code === "ATTENDANCE" || missionItem.mission_selected.code === 'REFER'){
        missionItem.conditions = JSON.stringify(this.attendance_conditions)
        this.form.is_multiple=1;
        this.form.allow_cancel =0;
        this.form.num_cancel =0;
      }
      if (missionItem.mission_selected.code === "AUTO_REGISTER") {
        let channels = [];
        let agencies = [];
        this.register_conditions.channels.forEach((item) => {
          channels.push(item.value);
        });
        this.register_conditions.agencies.forEach((item) => {
          agencies.push(item.value);
        });

        missionItem.conditions = JSON.stringify({
          channels: channels,
          agencies: agencies
        });
      }
      if (this.form.type ==1){
        Object.keys(missionItem).forEach(key => {
          if (key in this.mission_gifts) {
            missionItem[key] = this.mission_gifts[key]??'';
          }
        });
      }else {
        missionItem.type=5;
      }
      if (missionItem.type !==4){
        missionItem.combo_id = '';
      }
      missionItem.mission_id = missionItem.mission_selected.id
      return missionItem;
    },
    saveChallenge: async function () {

      this.form.display_segment_allow_user =[];
      this.form.display_segment_allow_block =[];
      this.form.join_segment_allow_user =[];
      this.form.join_segment_allow_block =[];
      this.$bus.$emit("show-loading", true);
      let missions_params = [];
      let missions_raw = JSON.stringify(this.missions);
      let missions = JSON.parse(missions_raw)
      missions.forEach((item,index)=>{
        missions_params.push(this.rebuildParamsMission(item,index))
      })
      this.form.data_mission = JSON.stringify(missions_params)
      this.form.info_gifts = JSON.stringify(this.info_gifts)
      let  that = this;
      if (this.display_segment.allow.length > 0){
        this.display_segment.allow.forEach((item) => {
          that.form.display_segment_allow_user.push(item.value);
        });
      }
      this.form.display_segment_allow_user = this.form.display_segment_allow_user.toString()

      if (this.display_segment.block.length  > 0){
        this.display_segment.block.forEach((item) => {
          that.form.display_segment_allow_block.push(item.value);
        });
      }
      this.form.display_segment_allow_block = this.form.display_segment_allow_block.toString()
      if (this.join_segment.allow.length > 0){
        this.join_segment.allow.forEach((item) => {
          that.form.join_segment_allow_user.push(item.value);
        });
      }

      this.form.join_segment_allow_user = this.form.join_segment_allow_user.toString()

      if (this.join_segment.block.length > 0){
        this.join_segment.block.forEach((item) => {
          that.form.join_segment_allow_block.push(item.value);
        });
      }
      this.form.join_segment_allow_block = this.form.join_segment_allow_block.toString()

      let params = this.form;
      CampaignRepository.createChallenge(params)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: response.data.message,
              });
              this.$router.push({ path: "/campaign/challenge" });
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = this.formInit();
      this.mission = {
        code:'',
        title:'',
        desc:'',
        type:'',
        campaign_code:'',
        quantity:'',
        point:'',
        amount:'',
        current_kpi_time:0,
      }
      this.payment_conditions = {
        type: 'DEFAULT',
        total_amount:'',
        min_per_trans:'',
        current_min_per_trans:'',
        list_service_code:[],
        list_service_code_child:[]
      }
      this.attendance_conditions = {
        num:1
      }
      this.display_segment= {
        allow:[],
        block:[],
      }
      this.join_segment= {
        allow:[],
        block:[],
      }
      this.$nextTick(() => {
        this.show = true;
      });
    },
    initSegment(){

      if (this.list_segment.length > 0 && this.list_segment_save.length > 0 ){
        let that = this;
        this.list_segment_save.forEach( (segment)=>{
          that.list_segment.forEach((item)=>{
            if (segment.segment_allow_user == item.value || segment.segment_allow_block == item.value){
              that.pushSegment(segment,item);

            }
          })
        })
      }
    },
    async listServiceCodeChange(index,is_load = false){

      const promises = this.payment_conditions[index].list_service_code.map(item => this.getServiceByType(item));
      try {
        // Chờ cho tất cả các promises hoàn thành
        const results = await Promise.all(promises);
        this.serviceCodeOption[index] = [];
        results.forEach((data)=>{
          data.forEach((item)=>{
            this.serviceCodeOption[index].push(item);
            if (is_load){
              this.serviceCodeOptionSelected[index] = this.serviceCodeOption[index].filter(it => this.payment_conditions[index].list_service_code_child.includes(it.value));
            }
            this.$forceUpdate();

          })

        })
      } catch (error) {
        alert(error)
      }



    },
    async getServiceByType(type) {
      const params = {
        type: type,
      };

      try {
        const response = await CmsRepository.listServiceByType(params);
        if (response.data.error_code) {
          alert(response.data.message);
        } else {
          return response.data.data;
        }
      } catch (error) {
        alert('Có lỗi xảy ra');
        throw error; // Rethrow lỗi để nó có thể được xử lý bởi hàm gọi cuộc gọi API.
      }
    },
    pushSegment(segment , item){
      if (segment.type === 1){
        if (segment.segment_allow_user !=0){
          this.display_segment.allow.push(item)
        }else {
          this.display_segment.block.push(item)
        }
      }else {
        if (segment.segment_allow_user !=0){
          this.join_segment.allow.push(item)
        }else {
          this.join_segment.block.push(item)
        }
      }

    }
  },
  async created() {
    this.getCodes();
    this.getMission();
    this.getTypeGift();
    this.getScripts();
    this.getSegment();
    this.getCombos();
    this.getCategoryList();
    await this.getServiceByType('BILLING')
    if (this.$route.params.id) {
      this.loadChallenge(this.$route.params.id);
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
